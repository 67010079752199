import React from "react"
import { Link } from "../../anti/utils/utils"

import logoNavbarDark from "../../../assets/img/brand/logo_ismaya-tv_dark.png"
import logoNavbarLight from "../../../assets/img/brand/logo_ismaya-tv_light.png"

const NavbarLogo = () => {
  return (
    <>
      <div className="navbar-wrapper absolute-top">
        <nav className="navbar transparent dark">
          <div className="container position-relative">
            <Link className="navbar-brand" to="/">
              <img
                src={logoNavbarDark}
                className="img-fluid dark"
                alt="Logo"
                width="auto"
              />
              <img
                src={logoNavbarLight}
                className="img-fluid light"
                alt="Logo"
                width="auto"
              />
            </Link>
          </div>
        </nav>
      </div>
    </>
  )
}

export default NavbarLogo
