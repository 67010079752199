import React, { useEffect, useState, useReducer } from "react"
import { nanoid } from "nanoid"
import { gsap } from "gsap"
import { Container, Section } from "../components/anti/grid/grid"
import SEO from "components/seo"

import { ACTIONS } from "utils/reducer/actions"
import { eventsReducer } from "utils/reducer/reducer"

// moment
import moment from "moment"

import Layout from "../components/layout"
import { Card } from "../components/anti/card/card"
import NavbarLogo from "components/global/navbar-logo/navbar-logo"

import { AcfCoverMainTwoRowTitle } from "components/gutenberg/acf-cover-main-two-row-title"

const revealCard = () => {
  gsap.to(".anim-img .card-bg > .el-after", {
    top: "0%",
    ease: "expo.out",
    duration: 0.5,
    delay: 0.3,
  })
  gsap.to(".anim-img .card-bg-img", {
    autoAlpha: "1",
    duration: 0,
    delay: 0.6,
  })
  gsap.to(".anim-img .card-body", {
    autoAlpha: "1",
    duration: 0,
    delay: 0.6,
  })
  gsap.to(".anim-img .card-bg > .el-after", {
    height: "0%",
    ease: "expo.out",
    duration: 0.5,
    delay: 0.8,
  })
}

const showDateEvent = ({ eventStartDate, eventEndDate, eventStartTime }) => {
  const dateStart = moment(eventStartDate)
  const dateEnd = moment(eventEndDate)

  if (!!eventEndDate) {
    return `${dateStart.format("DD MMM")} - ${dateEnd.format(
      "DD MMM YYYY"
    )}, every ${eventStartTime}`
  } else {
    return `${dateStart.format("ddd, DD MMM YYYY")} ${eventStartTime &&
      "• " + eventStartTime}`
  }
}

const getDayRemaining = ({
  eventStartDate,
  eventEndDate,
  eventStartTime,
  eventEndTime,
}) => {
  const upcomingTime = moment(eventStartDate).valueOf()
  const now = moment().valueOf()
  const getDayRemaining = moment(eventStartDate).diff(moment(), "days")
  const isSingular = getDayRemaining === 0 ? "" : "s"

  // moment.tz.setDefault("Asia/Jakarta")

  if (now >= upcomingTime) {
    let liveEnded = moment()
      .set({
        hour: eventEndTime.split(":")[0],
        minute: eventEndTime.split(":")[1],
      })
      .valueOf()

    if (!!eventEndDate && now >= liveEnded) {
      return (
        <>
          Live in <strong>1 Day</strong>
        </>
      )
    }

    return (
      <>
        Live <strong>Today</strong>
      </>
    )
  } else {
    return (
      <>
        Live in <strong>{`${getDayRemaining + 1} Day${isSingular}`}</strong>
      </>
    )
  }
}

const PagesTemplate = ({ location, pageContext }) => {
  // console.log({ location, pageContext })
  const [id] = useState(nanoid)
  const blocks = pageContext.data.blocks
  const events = pageContext.events.wordPress.events

  useEffect(() => {
    // Cover content
    gsap.to(".cover-bg", {
      scale: 1,
      ease: "expo.out",
      duration: 3,
      delay: 0.25,
    })
    gsap.from(".cover-title span", {
      y: 100,
      ease: "expo.inOut",
      duration: 2,
      delay: -1,
      stagger: { amount: 0.1 },
    })
    gsap.from(".sc-home-events .anim", {
      y: 100,
      ease: "expo.inOut",
      duration: 2,
      delay: -0.5,
      // stagger: { amount: 0.1 },
    })
  }, [])

  const ShortCodes = ({ data }) => {
    const [allEvents, eventsDispatch] = useReducer(
      eventsReducer,
      events && events.nodes
    )

    useEffect(() => {
      eventsDispatch({ type: ACTIONS.UPDATE_EVENTS })
      const interval = setInterval(() => {
        eventsDispatch({ type: ACTIONS.UPDATE_EVENTS })
      }, 1000)
      return () => clearInterval(interval)
    }, [])

    useEffect(() => {
      return () => {
        revealCard()
      }
    }, [allEvents])

    const liveEvents = allEvents.filter(item => item.status === "live")
    const upcomingEvents = allEvents.filter(item => item.status === "upcoming")
    const pastEvents = allEvents.filter(item => item.status === "past")

    if (data.attributes.text === "events") {
      return (
        <>
          {liveEvents.length !== 0 && (
            <Section className="sc-home-events live">
              <Container>
                <div className="overflow-hidden mb-sm-down-3 mb-4">
                  <h2 className="h5 anim">Live Now</h2>
                </div>
                <div className="row">
                  {liveEvents.map((node, i) => {
                    const blocks = node.blocks.find(
                      block => block.name === "acf/main-event"
                    )
                    const event = blocks && blocks.MainEvent
                    const { eventDatetime } = event

                    return (
                      <div key={i} className="col-md-4">
                        <Card
                          variant="overlay"
                          title={event.title}
                          titleClassName="h6"
                          text={showDateEvent(eventDatetime)}
                          img={event.coverThumbnail.sourceUrl}
                          imgHeight="h-ratio-16-9"
                          link={
                            !!event.externalLink
                              ? event.externalLink
                              : `/event/${node.slug}`
                          }
                          className="anim-img no-zoom-sm-down"
                          key={i}
                        />
                      </div>
                    )
                  })}
                </div>
              </Container>
            </Section>
          )}

          {upcomingEvents.length !== 0 && (
            <Section className="sc-home-events upcoming">
              <Container>
                <div className="overflow-hidden mb-sm-down-3 mb-4">
                  <h2 className="h5 anim">Upcoming</h2>
                </div>
                <div className="row">
                  {upcomingEvents.map((node, i) => {
                    const blocks = node.blocks.find(
                      block => block.name === "acf/main-event"
                    )
                    const event = blocks && blocks.MainEvent
                    const { eventDatetime } = event

                    return (
                      <div key={i} className="col-md-4">
                        <Card
                          variant="boxless"
                          title={event.title}
                          titleClassName="h6"
                          text={showDateEvent(eventDatetime)}
                          img={event.coverThumbnail.sourceUrl}
                          imgHeight="h-ratio-16-9"
                          link={
                            !!event.externalLink
                              ? event.externalLink
                              : `/event/${node.slug}`
                          }
                          className="anim-img no-zoom-sm-down"
                          key={i}
                        >
                          <div className="card-countdown">
                            {getDayRemaining(eventDatetime)}
                          </div>
                        </Card>
                      </div>
                    )
                  })}
                </div>
              </Container>
            </Section>
          )}

          {pastEvents.length !== 0 && (
            <Section className="sc-home-events past">
              <Container>
                <div className="overflow-hidden mb-sm-down-3 mb-4">
                  <h2 className="h5 anim">Past Event</h2>
                </div>
                <div className="row">
                  {pastEvents.map((node, i) => {
                    const blocks = node.blocks.find(
                      block => block.name === "acf/main-event"
                    )
                    const event = blocks && blocks.MainEvent
                    const { eventDatetime } = event

                    return (
                      <div key={i} className="col-md-4">
                        <Card
                          variant="boxless"
                          title={event.title}
                          titleClassName="h6"
                          text={showDateEvent(eventDatetime)}
                          img={event.coverThumbnail.sourceUrl}
                          imgHeight="h-ratio-16-9"
                          link={
                            !!event.externalLink
                              ? event.externalLink
                              : `/event/${node.slug}`
                          }
                          className="anim-img no-zoom-sm-down"
                          key={i}
                        />
                      </div>
                    )
                  })}
                </div>
              </Container>
            </Section>
          )}
        </>
      )
    }
  }

  return (
    <Layout>
      <NavbarLogo />

      <SEO title={pageContext.data.title} />
      {blocks &&
        blocks.map((block, i) => {
          switch (block.name) {
            case "acf/cover-main-two-row-title":
              return <AcfCoverMainTwoRowTitle data={block} key={`${id}${i}`} />
            case "core/shortcode":
              return <ShortCodes data={block} key={`${id}${i}`} />
            default:
              return <div key={`${id}${i}`}></div>
          }
        })}
    </Layout>
  )
}

export default PagesTemplate
