import React from "react"
import { graphql } from "gatsby"

import { Cover } from "../../anti/cover/cover"

export const AcfCoverMainTwoRowTitleBlock = graphql`
  fragment AcfCoverMainTwoRowTitleBlock on WordPress_AcfCoverMainTwoRowTitleBlock {
    attributes {
      className
    }
    coverMainTwoRowTitle {
      img {
        sourceUrl
        sourceUrlSharp {
          childImageSharp {
            fluid {
              base64
              src
              srcSet
              sizes
              aspectRatio
            }
          }
        }
      }
      titleLine1
      titleLine2
    }
  }
`

export const AcfCoverMainTwoRowTitle = ({ data }) => {
  const coverMainTwoRowTitle = data && data.coverMainTwoRowTitle
  return (
    coverMainTwoRowTitle && (
      <Cover
        variant="responsive"
        theme="dark"
        img={coverMainTwoRowTitle.img.sourceUrl}
        imgMd={coverMainTwoRowTitle.img.sourceUrl}
        imgHeight="h-ratio-16-9 h-md-500px"
        imgOverlay="30"
        // title={home.coverTitle}
        className="cover-home cover-full"
      >
        <h1 className="cover-title">
          <div className="overflow-hidden mb-1">
            <span className="d-block">{coverMainTwoRowTitle.titleLine1}</span>
          </div>
          <div className="overflow-hidden">
            <span className="d-block">{coverMainTwoRowTitle.titleLine2}</span>
          </div>
        </h1>
      </Cover>
    )
  )
}
